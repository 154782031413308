import { mapPlanning } from "../planning-service/mapper";
import { mapWorksite } from "../worksite-service/mapper";
import { Vehicle, VehicleData, VehicleFunctionnality, VehicleKPI, VehicleKPIData, VehicleKPIProducticityData } from "./models";

export function mapVehicle(data: any): Vehicle {
  let tags = data?.tags || [];
  if (typeof tags === "object") {
    tags = Object.keys(tags).map(function (index) {
      let tag = tags[index];
      return tag;
    });
  }

  return {
    uuid: data?.uuid,
    name: data?.name,
    mode: data?.mode,
    status: data?.status,
    groupName: data?.group?.name,
    tags: tags,
    companyName: data?.company?.name,
    companyId: data?.company?.uuid,
    groupId: data?.group?.uuid,
    deviceIdentifier: data?.device_identifier,
    isAccessByPincode: data?.is_access_by_pin_code,
    controllingDiscreteOutput: data?.controlling_discrete_output,
    isAccessByChecklist: data?.is_access_by_checklist,
    brand: data?.brand,
    products: data?.vehicles_products,
    category: data?.category,
    imageId: data?.image?.id,
    imageName: data?.image?.name,
    model: data?.model,
    worksiteId: data?.worksite_id,
    customBrand: data?.custom_brand,
    functionalities: mapVehicleFunctionalities(data?.functionalities?.map((el) => el.functionality)),
    worksite: mapWorksite(data?.worksite),
    planning: mapPlanning(data?.planning),
  };
}

export function mapVehicles(data: any): Vehicle[] {
  if (!data) {
    return [];
  }
  return data.map(mapVehicle);
}

export function mapVehicleData(data: any): VehicleData {
  if (!data) {
    return null;
  }
  return {
    operatingHours: data?.operating_hours_count,
    pedestrianDetections: data?.pedestrian_detections_count,
  };
}

export function mapVehicleFunctionality(data: any): VehicleFunctionnality {
  return {
    id: data?.id,
    key: data?.key,
  };
}

export function mapVehicleFunctionalities(data: any): VehicleFunctionnality[] {
  return data?.map(mapVehicleFunctionality) || [];
}

export function mapVehicleKpi(data: any): VehicleKPI {
  if (!data) {
    return null;
  }

  return {
    total_vehicles_count: data?.total_vehicles_count || 0,
    total_detections_count: data?.total_detections_count || 0,
    average_detection_per_day_over_operating_hours: data?.average_detection_per_day_over_operating_hours || 0,
    total_detections_cout_within_5_meters: data?.total_detections_cout_within_5_meters || 0,
    safety_score: data?.safety_score || 0,

    top_10_vehicles_operating_hours: data?.top_10_vehicles_operating_hours?.map((el) => mapVehicleKPIData(el)),
    top_10_most_productive_vehicles: data?.top_10_most_productive_vehicles?.map((el) => mapVehicleKPIProducticityData(el)) || [],

    detections_per_day: data?.detections_per_day?.map((el) => mapVehicleKPIData(el)) || [],
    average_dectection_per_day: data?.average_dectection_per_day || 0,
    detections_per_hour: data?.detections_per_hour?.map((el) => mapVehicleKPIData(el)) || [],
    average_distance_per_vehicle: data?.average_distance_per_vehicle?.map((el) => mapVehicleKPIData(el)) || [],
  };
}
function mapVehicleKPIData(data: any): VehicleKPIData {
  return {
    label: data?.label,
    value: data?.value,
  };
}

function mapVehicleKPIProducticityData(data: any): VehicleKPIProducticityData {
  return {
    label: data?.label,
    detections: data?.detections,
    detections_per_operating_hours: data?.detections_per_operating_hours,
  };
}
