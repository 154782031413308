import {ChangePasswordResponse, NhostSession, SignUpOptions,} from "@nhost/hasura-auth-js";
import {authClient} from "@/plugins/nhost";

const isSignedIn = (): boolean => authClient.isAuthenticated();
const isSignedInAsync = (): Promise<boolean> => authClient.isAuthenticatedAsync();
const getSession = (): NhostSession | null => authClient.getSession();

function handleResultAuth(result: { error: any }) {
  if (result.error !== null) {
    throw result.error;
  }

  return result;
}

async function signOut(): Promise<void> {
  const res = await authClient.signOut();

  handleResultAuth(res);
}

async function signIn(email: string): Promise<void> {
  const res = await authClient.signInEmailOTP(email);
  handleResultAuth(res);
}

async function validateOtp(email: string, code: string): Promise<void> {
  const res = await authClient.verifyEmailOTP(email, code);

  handleResultAuth(res);
}

async function signInWithToken(refreshToken: string): Promise<void> {
  await authClient.refreshSession(refreshToken);
}

async function register(
  email: string,
  password: string,
  options?: SignUpOptions
): Promise<void> {
  const res = await fetch(
    `${import.meta.env.VITE_NHOST_AUTH_BACKEND_URL}/signup/email-password`,
    {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
        options: options,
      }),
      headers: {"Content-Type": "application/json"},
      credentials: "same-origin",
    }
  );
  const response = await res.json();
  if (!response.ok) {
    throw response;
  }
  return response;
}

async function changePassword(
  password: string
): Promise<ChangePasswordResponse> {
  const res = await authClient.changePassword({
    newPassword: password,
  });

  return handleResultAuth(res);
}

async function signInWithGoogle() {
  const res = await authClient.signIn({
    provider: "google",
    options: {
      redirectTo: `${window.location.protocol}//${window.location.host}`,
    },
  });

  return handleResultAuth(res);
}

async function resetPassword(email: string) {
  const res = await authClient.resetPassword({
    email,
    options: {
      redirectTo: `${window.location.origin}/set-password`,
    },
  });

  return handleResultAuth(res);
}

export function useAuthDao() {
  return {
    isSignedIn,
    getSession,
    signOut,
    signIn,
    signInWithToken,
    register,
    changePassword,
    signInWithGoogle,
    resetPassword,
    isSignedInAsync,
    validateOtp
  };
}
