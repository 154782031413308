import {gql} from "@apollo/client/core";

export const CHECK_CREDENTIALS = gql`
query checkCredential($email: String!, $password: String!){
  check_credential(email: $email, password: $password) {
    success
    disabled
  }
}
`
